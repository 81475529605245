import { ResponsiveLine } from '@nivo/line';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const nomeMes = {
  0: 'JAN',
  1: 'FEV',
  2: 'MAR',
  3: 'ABR',
  4: 'MAI',
  5: 'JUN',
  6: 'JUL',
  7: 'AGO',
  8: 'SET',
  9: 'OUT',
  10: 'NOV',
  11: 'DEZ',
};

const LineChart = ({ data, ...rest /* see data tab */ }) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 50, right: 25, bottom: 35, left: 25 }}
    xScale={{ type: 'point' }}
    yScale={{
      type: 'linear',
      min: 'auto',
      max: 'auto',
      stacked: true,
      reverse: false,
    }}
    yFormat=" >-.2f"
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: 'bottom',
      tickSize: 10,
      tickPadding: 5,
      tickRotation: 0,
      legendOffset: 36,
      legendPosition: 'middle',
      format: (e) => nomeMes[Number(e - 1)],
    }}
    enableSlices="x"
    sliceTooltip={({ slice }) => (
      <div
        style={{
          background: 'white',
          padding: '9px 12px',
          border: '1px solid #ccc',
        }}
      >
        <div className="bold">Mês: {slice.points[0].data?.x}</div>
        {slice.points.map((point) => (
          <div
            key={point.id}
            style={{
              color: point.serieColor,
              padding: '3px 0',
            }}
          >
            <strong>
              {point.serieId}: {point.data.y}
            </strong>
          </div>
        ))}
      </div>
    )}
    theme={{
      axis: {
        ticks: {
          text: {
            fontWeight: 600,
          },
        },
      },
    }}
    {...rest}
    // tooltip={(e) => (
    //   <div
    //     style={{
    //       border: `1px solid ${e.borderColor}`,
    //     }}
    //   >
    //     <span
    //       style={{
    //         background: e.serieColor,
    //         padding: 5,
    //       }}
    //     />
    //     Mês: {data.x} - Acidentes: {data.y}
    //   </div>
    // )}
    // axisLeft={{
    //   orient: 'left',
    //   tickSize: 5,
    //   tickPadding: 5,
    //   tickRotation: 0,
    //   legend: 'QTD',
    //   legendOffset: -40,
    //   legendPosition: 'middle',
    // }}
    axisLeft={null}
    pointSize={10}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabelYOffset={-12}
    colors={{ scheme: 'category10' }}
    useMesh
    legends={[
      {
        anchor: 'top-left',
        direction: 'row',
        justify: false,
        translateX: -10,
        translateY: -43,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 65,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
  />
);

export default LineChart;
