/* eslint-disable react/no-unescaped-entities */
// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { ResponsiveBar } from '@nivo/bar';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const BarChart = ({ data, ...rest }) => (
  <ResponsiveBar
    data={data}
    indexBy="ano"
    margin={{ top: 20, right: 20, bottom: 50, left: 20 }}
    // colors={() => '#0100be'}
    // labelTextColor="#fff"
    // labelTextColor="#0100be"
    labelTextColor="#000"
    borderColor={{
      from: 'color',
      modifiers: [['darker', 1.6]],
    }}
    axisRight={null}
    axisTop={null}
    valueScale={{
      type: 'symlog',
    }}
    animate={false}
    // axisBottom={{
    //   tickSize: 5,
    //   tickPadding: 5,
    //   tickRotation: 0,
    //   legend: 'Ano',
    //   legendPosition: 'middle',
    //   legendOffset: 32,
    // }}
    axisLeft={null}
    labelSkipWidth={12}
    labelSkipHeight={12}
    role="application"
    ariaLabel="Nivo bar chart demo"
    theme={{
      labels: {
        text: {
          fontWeight: 600,
        },
      },
    }}
    barAriaLabel={function (e) {
      return `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`;
    }}
    {...rest}
  />
);

export default BarChart;
