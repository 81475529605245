/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useRef, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Form } from '@unform/web';
import Pagination from 'react-paginate';
import {
  Card,
  CardBody,
  CardHeader,
  Table,
  Row,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { FiMoreVertical } from 'react-icons/fi';
import { IoFolderOutline } from 'react-icons/io5';
import api from '../../services/api';
import useValidator from '../../hooks/useValidator';
import { maskDocumento } from '../../utils';
import { currencyConfig } from '../../utils/currency';
import { Input, ReactSelectAsync, Select } from '../../components/unform';
import getQuery from '../../utils/getQuery';
import { useAuth } from '../../contexts/auth';
import 'react-json-view-lite/dist/index.css';

function formatarData(dataString) {
  if (dataString) {
    return new Date(dataString)
      .toLocaleDateString('pt-BR', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
      .replace(',', '');
  }

  return '';
}

function ListarConciliacoes() {
  const { showLoader, closeLoader, toast } = useValidator();
  const { user } = useAuth();
  const [modal, setModal] = useState(false);
  const [result, setResult] = useState({
    items: [],
    pages: 0,
    count: 0,
    itemsPorPagina: 0,
  });
  const filtrosRef = useRef();
  const debounce = useRef();
  const selectOptions = useRef({});

  const toggleModal = () => {
    setModal(!modal);
  };

  const getData = async (page = 1) => {
    try {
      showLoader();
      const query = getQuery(filtrosRef.current.getData());
      const { data } = await api.get(`/listarconcilia?page=${page}&${query}`);
      setResult({
        pages: Number(data.pages),
        count: Number(data.total),
        items: data.integracoesEnvio.map((m) => ({
          ...m,
          retorno: data.integracoesRetorno.find(
            (f) => f.conciliacaoenvio_id === m.id
          ),
        })),
      });
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  };

  async function filtrarConsulta(inputValue, tipo) {
    const { data } = await api.get(`/pessoas?nome=${inputValue}&tipo=${tipo}`);

    return data?.map((m) => ({
      documento: m?.Usuario?.documento,
      value: m?.Usuario?.documento,
      label: m?.nome,
    }));
  }

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  const optionsConsulta = async (inputValue, tipo) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current[tipo]);

      selectOptions.current[tipo] = setTimeout(() => {
        resolve(filtrarConsulta(inputValue, tipo));
      }, 500);
    });

  return (
    <>
      <Card>
        <CardHeader className="bg--dark-blue p-3 mb-3 d-flex justify-content-between">
          <h4 className="color-white mb-0">Conciliação</h4>
        </CardHeader>
        <CardBody>
          <Form onSubmit={() => getData()} ref={filtrosRef}>
            <Row>
              {user?.perfil !== 'F' && (
                <Col>
                  <ReactSelectAsync
                    isClearable
                    name="cnpj_fomento"
                    label="Financiador"
                    placeholder="Selecione"
                    loadOptions={(txt) => optionsConsulta(txt, 'F')}
                    onChange={filtrarPesquisa}
                    defaultOptions
                  />
                </Col>
              )}
              <Col>
                <Input
                  className="form-control"
                  name="codigo_erp"
                  label="Código ERP"
                  onChange={filtrarPesquisa}
                />
              </Col>
              <Col>
                <Select
                  name="status"
                  className="form-control"
                  label="Status"
                  onChange={filtrarPesquisa}
                  data={[
                    {
                      value: '',
                      label: 'Status',
                    },
                    {
                      value: 'Enviado',
                      label: 'Enviado',
                    },
                    {
                      value: 'Pendente',
                      label: 'Pendente',
                    },
                    {
                      value: 'Erro',
                      label: 'Erro',
                    },
                  ]}
                />
              </Col>
              <Col lg={2}>
                <Input
                  className="form-control"
                  name="sugestao_inicio"
                  label="Data sugestão início"
                  onChange={filtrarPesquisa}
                  type="date"
                  isClearable
                />
              </Col>
              <Col lg={2}>
                <Input
                  className="form-control"
                  name="sugestao_fim"
                  label="Data sugestão fim"
                  onChange={filtrarPesquisa}
                  type="date"
                />
              </Col>
            </Row>
          </Form>
          <Table className="rwd-table min-height" responsive>
            <thead>
              <tr>
                <th>Financiador</th>
                <th>Código ERP</th>
                <th>Qtd. Operações</th>
                <th>Saldo devedor total</th>
                <th>Status</th>
                <th className="text-end">Ações</th>
              </tr>
            </thead>
            <tbody>
              {result.items.map((m) => (
                <tr key={m?.id}>
                  <td data-label="Financiador">
                    {maskDocumento(m?.cnpj_fomento)}
                  </td>
                  <td data-label="Código ERP">{m?.codigo_erp}</td>
                  <td data-label="Qtd. Operações">
                    {m?.qtde_operacao_detalhe}
                  </td>
                  <td data-label="Saldo devedor total">
                    {Number(m?.saldo_devedor_total).toLocaleString(
                      ...currencyConfig
                    )}
                  </td>
                  <td data-label="Status">{m?.status}</td>
                  <td data-label="Ações">
                    <div className="d-flex justify-content-end">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="light-blue"
                          className="text-decoration-none padding-0-5"
                        >
                          <FiMoreVertical />
                        </DropdownToggle>
                        <DropdownMenu end container="body">
                          <DropdownItem onClick={() => setModal(m)}>
                            <IoFolderOutline className="mr-1" />
                            Dados de retorno operação
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
              ))}
              {result.items?.length === 0 && (
                <tr>
                  <td colSpan={5} className="text-center">
                    Sem dados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
        <div className="d-flex-wrap justify-content-end">
          <p className="mt-1 pr-2">
            {result.items.length}
            {' de '}
            {result.count}
          </p>
          <Pagination
            nextLabel={<FaChevronRight />}
            previousLabel={<FaChevronLeft />}
            pageCount={result.pages}
            onPageChange={({ selected }) => getData(selected + 1)}
            initialPage={0}
            containerClassName="pagination justify-end mr-1"
            activeClassName="active"
          />
        </div>
      </Card>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Dados</ModalHeader>

        <ModalBody>
          <Row>
            <Col lg={3}>
              <h5>Cnpj financiador</h5>
              <p>{maskDocumento(modal?.cnpj_fomento)}</p>
            </Col>
            <Col lg={3}>
              <h5>Código ERP</h5>
              <p>{modal?.codigo_erp}</p>
            </Col>
            <Col lg={3}>
              <h5>Qtd. operações</h5>
              <p>{modal?.qtde_operacao_detalhe}</p>
            </Col>
            <Col lg={3}>
              <h5>Saldo devedor</h5>
              <p>
                {Number(modal?.saldo_devedor_total).toLocaleString(
                  ...currencyConfig
                )}
              </p>
            </Col>
          </Row>
          <hr />
          <h4>Dados retorno</h4>
          <Row className="mt-3">
            <Col lg={3}>
              <h5>Protocolo</h5>
              <p>{modal?.retorno?.protocolo}</p>
            </Col>
            <Col lg={3}>
              <h5>Ref. externa</h5>
              <p>{modal?.retorno?.referencia_externa}</p>
            </Col>
            <Col lg={3}>
              <h5>Data processamento</h5>
              <p>{formatarData(modal?.retorno?.data_hora_processamento)}</p>
            </Col>
            <Col lg={3}>
              <h5>Status</h5>
              <p>{modal?.retorno?.status}</p>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ListarConciliacoes;
