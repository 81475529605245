import React, { useRef } from 'react';
import { Form } from '@unform/web';
import {
  Button,
  Card,
  Col,
  Row,
  CardBody,
  CardHeader,
  CardFooter,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Input, ReactSelectAsync, Select } from '../../components/unform';
import useValidator from '../../hooks/useValidator';
import api from '../../services/api';

function CriarAcordo() {
  const { showLoader, closeLoader, toast, validator } = useValidator();
  const history = useHistory();
  const selectOptions = useRef({});
  const formRef = useRef();

  async function handleSubmit(body) {
    const { errors, hasError } = validator(body);
    formRef.current.setErrors(errors);
    if (hasError) {
      return toast();
    }

    showLoader();
    try {
      await api.post('/optin/', body);
      closeLoader();
      history.push('/acordos');
    } catch (err) {
      closeLoader();
      toast(err.mensagem);
    }
  }

  async function filtrarConsulta(inputValue, perfil) {
    const { data } = await api.get(
      `/pessoas?nome=${inputValue}&perfil=${perfil || ''}`
    );

    return data.map((m) => ({ value: m.id, label: m.nome }));
  }

  const optionsConsulta = async (inputValue, perfil) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current[perfil]);

      selectOptions.current[perfil] = setTimeout(() => {
        resolve(filtrarConsulta(inputValue, perfil));
      }, 500);
    });

  return (
    <>
      <Card>
        <CardHeader className="bg--dark-blue p-3 mb-3">
          <h4 className="color-white mb-0">Novo contrato</h4>
        </CardHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <CardBody>
            <Row>
              <Col lg={4} md={6} sm={12}>
                <Select
                  name="tipo_operacao"
                  label="Tipo operação *"
                  className="form-control"
                  data={[
                    {
                      label: 'Selecione',
                      value: '',
                    },
                    {
                      label: 'Criar',
                      value: 'C',
                    },
                    {
                      label: 'Alterar',
                      value: 'A',
                    },
                  ]}
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <ReactSelectAsync
                  isClearable
                  name="fomento_id"
                  label="Financiador *"
                  placeholder="Selecione"
                  loadOptions={(txt) => optionsConsulta(txt, 'F')}
                  defaultOptions
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <ReactSelectAsync
                  isClearable
                  name="estabelecimento_id"
                  label="Estab. comercial *"
                  placeholder="Selecione"
                  loadOptions={(txt) => optionsConsulta(txt, 'E')}
                  defaultOptions
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <Input
                  name="data_assinatura"
                  label="Data assinatura *"
                  className="form-control"
                  type="datetime-local"
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <Input
                  name="data_inicio"
                  label="Data início *"
                  type="date"
                  className="form-control"
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <Input
                  name="data_fim"
                  label="Data fim *"
                  type="date"
                  className="form-control"
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="justify-content-between d-flex">
            <p className="mb-0 text-muted"> * (Obrigatório)</p>
            <Button type="submit" color="primary">
              Criar
            </Button>
          </CardFooter>
        </Form>
      </Card>
    </>
  );
}

export default CriarAcordo;
