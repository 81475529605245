import { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

export default function Radio({
  name,
  keyName,
  label,
  data,
  defaultChecked,
  ...rest
}) {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue = '', error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs,
      getValue: (refs) => refs.current.find((input) => input?.checked)?.value,
      setValue: (refs, value) => {
        const inputRef = refs.current.find(
          (ref) => ref.value === String(value)
        );
        if (inputRef) inputRef.checked = true;
      },
      clearValue: (refs) => {
        const inputRef = refs.current.find((ref) => ref.checked === true);
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [fieldName, registerField]);

  return (
    <div>
      {label && <label className="label-form">{label}</label>}
      <div className="d-flex">
        {data.map((option, index) => (
          <span key={option.id || option.value || option.label}>
            <label htmlFor={option.id} key={option.id} className="cbx">
              <div className="d-flex pt-2 pb-1 px-2">
                <input
                  type="radio"
                  ref={(ref) => {
                    inputRefs.current[index] = ref;
                  }}
                  id={option.id}
                  name={`${name}-${keyName}`}
                  defaultChecked={
                    (defaultChecked || defaultValue) === option.value
                  }
                  value={option.value}
                  {...rest}
                />
                &nbsp;
                <span style={{ fontSize: 16 }}>{option.label}</span>
              </div>
            </label>
          </span>
        ))}
      </div>

      {error && <span>{error}</span>}
    </div>
  );
}
