/* eslint-disable camelcase */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Table,
} from 'reactstrap';
import api from '../../services/api';
import useValidator from '../../hooks/useValidator';
import { Input, ReactSelect, ReactSelectAsync } from '../../components/unform';
import { currencyConfig } from '../../utils/currency';
import { maskDocumento } from '../../utils';
import { CheckBox, ToolTipitem } from '../../components/index';
import { imgs, nomes } from './bandeiras';
import { useAuth } from '../../contexts/auth';

function formatData(dataString) {
  if (!dataString) {
    return 'N/A';
  }

  const splitString = dataString.split('-');

  return `${splitString[2]}/${splitString[1]}/${splitString[0]}`;
}

function calcularDiasRestantes(dataAlvo) {
  const dataAlvoObj = new Date(dataAlvo);
  const dataAtual = new Date();
  const diferencaEmMilissegundos = dataAlvoObj - dataAtual;
  const diasRestantes = Math.ceil(
    diferencaEmMilissegundos / (1000 * 60 * 60 * 24)
  );

  return diasRestantes;
}

function adicionarDias(dias) {
  // Converte a data base para um objeto Date
  const data = new Date();

  // Adiciona os dias à data
  data.setDate(data.getDate() + dias + 1);

  // Obtém o ano, mês e dia da data resultante
  const ano = data.getFullYear();
  const mes = String(data.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
  const dia = String(data.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário

  // Retorna a data no formato "yyyy-mm-dd"
  return `${ano}-${mes}-${dia}`;
}

function Agenda() {
  const { user } = useAuth();
  const { showLoader, closeLoader, toast, validator } = useValidator([
    'cnpj_fomento',
  ]);
  const [defaultDate, setDefaultDate] = useState(null);
  const [defaultDateEnd, setDefaultDateEnd] = useState(null);
  const [result, setResult] = useState({
    items: [],
    pages: 0,
    count: 0,
    itemsPorPagina: 0,
  });
  const [hasSuggestedCnpj, setHasSuggestedCnpj] = useState('');
  const [selected, setSelected] = useState([]);
  const selectOptions = useRef();
  const formRef = useRef();

  useEffect(() => {
    if (user?.dias_liquidacao_agenda) {
      setDefaultDate(adicionarDias(user?.dias_liquidacao_agenda));
      setDefaultDateEnd(
        adicionarDias(Number(user?.dias_liquidacao_agenda) + 30)
      );
    }
  }, [user]);

  const handleSubmit = async (dados) => {
    const { errors, hasError } = validator(dados);
    formRef.current.setErrors(errors);
    if (hasError) {
      return toast();
    }

    try {
      showLoader();
      dados.perfil = user.perfil;
      dados.id_fomento_ec =
        user.perfil === 'F'
          ? formRef.current.getFieldRef('cnpj_recebedor').select.state.value
          : formRef.current.getFieldRef('cnpj_fomento').select.state.value;

      const { data } = await api.post('/agenda', dados);

      const { fator_compra: ft, outras_despesas: od } = data?.pessoaFomento;

      const fator_compra = data?.taxas?.fator_compra || ft;
      const outras_despesas = data?.taxas?.outras_despesas || od;

      const items = [];
      data?.agenda?.agendas?.forEach((f) => {
        if (!f?.unidadesRecebiveis?.length) {
          return;
        }
        f?.unidadesRecebiveis?.forEach((recebivel) => {
          const diasVencimento = calcularDiasRestantes(
            recebivel.dataLiquidacao
          );
          if (recebivel.valorTotalUR > 0 && recebivel.valorLivre > 0) {
            items.push({
              dataLiquidacao: recebivel.dataLiquidacao,
              valorTotalUR: recebivel.valorTotalUR,
              titular: recebivel?.titulares?.[0]?.documentoTitular,
              instituicaoCredenciadora: f.instituicaoCredenciadora,
              codigoArranjoPagamento: f.codigoArranjoPagamento,
              valorLivre: recebivel?.valorLivre,
              valorSimulado:
                Number(recebivel?.valorLivre) -
                (Number(recebivel?.valorLivre) *
                  (Number(fator_compra) / 100) *
                  (diasVencimento / 30) +
                  Number(outras_despesas)),
              key: `${recebivel.dataLiquidacao}-${recebivel?.titulares?.[0]?.documentoTitular}-${f.instituicaoCredenciadora}-${f.codigoArranjoPagamento}`,
            });
          }
        });
      });
      Array.from(document.querySelectorAll('[name="ur"]')).forEach((m) => {
        m.checked = false;
      });
      setResult({
        ec_fo:
          user.perfil === 'F'
            ? formRef.current.getFieldRef('cnpj_recebedor').select.state.value
            : formRef.current.getFieldRef('cnpj_fomento').select.state.value,
        items,
        count: items.length,
        valorTotal: items.reduce((a, b) => a + b.valorTotalUR, 0),
      });
      setSelected([]);
      closeLoader();
    } catch (err) {
      toast(err?.mensagem);
      closeLoader();
    }
  };

  const handleSugerir = async () => {
    if (!selected.length) {
      return toast('Selecione ao menos uma UR');
    }

    try {
      showLoader();
      await api.post('/operacoes/sugerir', {
        urs: selected,
        ec_fo_id: result?.ec_fo?.id,
      });
      closeLoader();
      toast('Sugestão cadastrada com sucesso', { type: 'success' });
    } catch (err) {
      toast(err?.mensagem);
      closeLoader();
    }
  };

  async function filtrarConsulta(inputValue) {
    const { data } = await api.get(`/pessoas?nome=${inputValue}`);

    return data.map((m) => ({
      value: m?.Usuario?.documento,
      label: m?.nome,
      id: m?.id,
      dias_agenda: m?.dias_liquidacao_agenda,
    }));
  }

  const optionsConsulta = async (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current);

      selectOptions.current = setTimeout(() => {
        resolve(filtrarConsulta(inputValue));
      }, 500);
    });

  function formatOptionsBandeira() {
    const options = [];

    Object.entries(nomes).forEach(([key, value]) => {
      Object.entries(imgs).forEach(([keyImg, valueImg]) => {
        if (key === keyImg) {
          options.push({
            value: key,
            label: value,
            img: valueImg,
          });
        }
      });
    });

    return options;
  }

  const isFomento = useMemo(() => user?.perfil === 'F', [user?.perfil]);

  return (
    <>
      <Card>
        <CardHeader className="bg--dark-blue p-3 mb-3 d-flex justify-content-between">
          <h4 className="color-white mb-0">Agenda</h4>
        </CardHeader>
        <CardBody>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Row>
              <Col lg={2}>
                <Input
                  className="form-control"
                  name="data_inicio"
                  label="De *"
                  type="date"
                  defaultValue={defaultDate}
                />
              </Col>
              <Col lg={2}>
                <Input
                  className="form-control"
                  name="data_fim"
                  label="Até *"
                  type="date"
                  defaultValue={defaultDateEnd}
                />
              </Col>
              <Col lg={3}>
                {isFomento ? (
                  <ReactSelectAsync
                    isClearable
                    name="cnpj_recebedor"
                    label="Estabelecimento *"
                    placeholder="Selecione"
                    loadOptions={(txt) => optionsConsulta(txt)}
                    defaultOptions
                  />
                ) : (
                  <ReactSelectAsync
                    isClearable
                    name="cnpj_fomento"
                    label="Sugerir para financiador"
                    placeholder="Selecione"
                    loadOptions={(txt) => optionsConsulta(txt)}
                    defaultOptions
                    onChange={(e) => {
                      setHasSuggestedCnpj(e.id);
                      if (e) {
                        formRef?.current?.setFieldValue(
                          'data_inicio',
                          adicionarDias(
                            e?.dias_agenda || e?.dias_liquidacao_agenda
                          )
                        );
                        formRef?.current?.setFieldValue(
                          'data_fim',
                          adicionarDias(
                            (e?.dias_agenda || e?.dias_liquidacao_agenda) + 30
                          )
                        );
                      } else {
                        formRef?.current?.setFieldValue('data_inicio', '');
                        formRef?.current?.setFieldValue('data_fim', '');
                      }
                    }}
                  />
                )}
              </Col>
              <Col>
                <ReactSelect
                  isClearable
                  isMulti
                  name="bandeiras"
                  label="Bandeiras"
                  placeholder="Selecione"
                  defaultOptions
                  options={formatOptionsBandeira()}
                  formatOptionLabel={(bandeira) => (
                    <div className="country-option">
                      <img
                        alt={bandeira?.label}
                        src={require(`../../assets/svg/bandeiras/${bandeira?.img}`)}
                        width={35}
                      />
                      <span className="ml-3">{bandeira.label}</span>
                    </div>
                  )}
                />
              </Col>
              <Col lg={2}>
                <div className="mt-1">
                  <label>&nbsp;</label>
                </div>
                <Button color="primary" block>
                  Consultar
                </Button>
              </Col>
            </Row>
            <Row>
              <p style={{ fontSize: '12px' }}>
                * Data inicio respeita a configuração efetuada pelo Financiador
              </p>
            </Row>
          </Form>
          <Table className="rwd-table min-height" responsive>
            <thead>
              <tr>
                <th>Liquidação</th>
                {!['F', 'E'].includes(user?.perfil) && <th>Credenciador</th>}
                <th>Fiananciador</th>
                <th>Valor total da UR</th>
                <th>Valor livre a onerar</th>
                <th>Valor livre simulado</th>
                <th>Arranjo</th>
                <th>Bandeira</th>
                <th className="text-end w-6">
                  <div className="d-flex justify-content-end align-items-center">
                    Todos
                    <CheckBox
                      id="todos"
                      name="ur"
                      checked={
                        result.items?.length > 0 &&
                        selected.length === result.items?.length
                      }
                      disabled={
                        !result?.items?.length ||
                        (!isFomento && !hasSuggestedCnpj)
                      }
                      onChange={(e) => {
                        Array.from(
                          document.querySelectorAll('[name="ur"]')
                        ).forEach((m) => {
                          m.checked = e.target.checked;
                        });

                        if (e.target.checked) {
                          setSelected(result.items);
                        } else {
                          setSelected([]);
                        }
                      }}
                      value="todos"
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                style={{
                  marginBottom: 0,
                  boxShadow: '0px 0px 0px transparent',
                  border: '1px solid rgba(0,0,0,0.1)',
                }}
                className="d-block-sm d-md-none"
              >
                <td data-label="Marcar/desmarcar todos">
                  <div
                    className="d-flex justify-content-end"
                    style={{
                      marginTop: -15,
                    }}
                  >
                    <CheckBox
                      id="todos"
                      name="ur"
                      checked={
                        result.items?.length > 0 &&
                        selected.length === result.items?.length
                      }
                      disabled={
                        !result?.items?.length ||
                        (!isFomento && !hasSuggestedCnpj)
                      }
                      onChange={(e) => {
                        Array.from(
                          document.querySelectorAll('[name="ur"]')
                        ).forEach((m) => {
                          m.checked = e.target.checked;
                        });

                        if (e.target.checked) {
                          setSelected(result.items);
                        } else {
                          setSelected([]);
                        }
                      }}
                      value="todos"
                    />
                  </div>
                </td>
              </tr>
              {result.items?.map((m) => (
                <tr key={m.key}>
                  <td data-label="Liquidação">
                    {formatData(m.dataLiquidacao)}
                  </td>
                  {!['F', 'E'].includes(user?.perfil) && (
                    <td data-label="Credenciador">
                      {maskDocumento(m.titular)}
                    </td>
                  )}
                  <td data-label="Financiador">
                    {maskDocumento(m.instituicaoCredenciadora)}
                  </td>
                  <td data-label="Valor total da UR">
                    {Number(m.valorTotalUR).toLocaleString(...currencyConfig)}
                  </td>
                  <td data-label="Valor livre a onerar">
                    {Number(m.valorLivre).toLocaleString(...currencyConfig)}
                  </td>
                  <td data-label="Valor livre simulado">
                    {Number(m.valorSimulado).toLocaleString(...currencyConfig)}
                  </td>
                  <td data-label="Arranjo">{m?.codigoArranjoPagamento}</td>
                  <td data-label="Bandeira">
                    {imgs[m?.codigoArranjoPagamento] ? (
                      <ToolTipitem text={nomes[m?.codigoArranjoPagamento]}>
                        <img
                          width={60}
                          height={25}
                          alt={m?.bandeira}
                          src={require(`../../assets/svg/bandeiras/${
                            imgs[m?.codigoArranjoPagamento]
                          }`)}
                          className="mr-3 object-fit"
                        />
                      </ToolTipitem>
                    ) : (
                      <ToolTipitem text={nomes[m?.codigoArranjoPagamento]}>
                        <span className="badge badge-primary color-primary-dark">
                          {m?.codigoArranjoPagamento}
                        </span>
                      </ToolTipitem>
                    )}
                  </td>
                  <td data-label="Ações">
                    <div className="d-flex justify-content-end">
                      <CheckBox
                        id={m.key}
                        name="ur"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelected((old) => old.concat(m));
                          } else {
                            setSelected((old) =>
                              old.filter((f) => f.key !== m.key)
                            );
                          }
                        }}
                        disabled={!isFomento && !hasSuggestedCnpj}
                        value={m.key}
                      />
                    </div>
                  </td>
                </tr>
              ))}
              {result.items?.length === 0 && (
                <tr>
                  <td colSpan={8} className="text-center">
                    Sem dados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
        <div className="d-flex-wrap justify-content-between px-3">
          <div>
            <div>
              <p>
                Total de UR(s): <strong>{result.count}</strong>
              </p>
            </div>
            <div>
              <p>
                Total:{' '}
                <strong>
                  {Number(result.valorTotal || 0).toLocaleString(
                    ...currencyConfig
                  )}
                </strong>
              </p>
            </div>
          </div>
          <div>
            <div>
              <p>
                Total selecionado: <strong>{selected.length}</strong>
              </p>
            </div>
            <div>
              <p>
                Total livre:{' '}
                <strong>
                  {Number(
                    selected.reduce((a, b) => a + b.valorLivre, 0) || 0
                  ).toLocaleString(...currencyConfig)}
                </strong>
              </p>
            </div>
            <div>
              <p>
                Simulado livre:{' '}
                <strong>
                  {Number(
                    selected.reduce((a, b) => a + b.valorSimulado, 0) || 0
                  ).toLocaleString(...currencyConfig)}
                </strong>
              </p>
            </div>
          </div>
        </div>
        <CardFooter>
          <Row className="w-100 justify-content-center">
            <Col lg={4}>
              <Button
                block
                color="primary"
                onClick={handleSugerir}
                disabled={!selected.length}
              >
                Sugerir
              </Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
}

export default Agenda;
