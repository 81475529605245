/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useRef, useState } from 'react';
import { FiMoreVertical, FiEye } from 'react-icons/fi';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Form } from '@unform/web';
import Pagination from 'react-paginate';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  UncontrolledDropdown,
  Row,
  Col,
} from 'reactstrap';
import { IoFolderOutline, IoTrashOutline } from 'react-icons/io5';
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import api from '../../services/api';
import useValidator from '../../hooks/useValidator';
import { maskDocumento } from '../../utils';
import { currencyConfig } from '../../utils/currency';
import { imgs, nomes } from '../Agenda/bandeiras';
import { ToolTipitem } from '../../components';
import { Input, ReactSelectAsync, Select } from '../../components/unform';
import getQuery from '../../utils/getQuery';
import { useAuth } from '../../contexts/auth';
import 'react-json-view-lite/dist/index.css';

function formatarData(dataString) {
  if (dataString) {
    const data = new Date(dataString);
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    const hora = String(data.getHours()).padStart(2, '0');
    const minutos = String(data.getMinutes()).padStart(2, '0');

    return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
  }
}

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function getQueryParams(search) {
  const query = search.split('&').reduce((acc, it) => {
    const [key, value] = it.split('=');
    acc[key] = value;
    return acc;
  }, {});
  return query;
}

function ListarOperacoes() {
  const { showLoader, closeLoader, toast, validator } = useValidator();
  const { user } = useAuth();
  const [result, setResult] = useState({
    items: [],
    pages: 0,
    count: 0,
    itemsPorPagina: 0,
  });
  const { search } = useLocation();
  const queryParams = getQueryParams(search);
  const [erro, setErro] = useState({ id: null, obj: {} });
  const [modal, setModal] = useState(false);
  const [modalAceite, setModalAceite] = useState(false);
  const [modalErro, setModalErro] = useState(false);
  const formRef = useRef();
  const filtrosRef = useRef();
  const debounce = useRef();
  const selectOptions = useRef({});
  const toggleModal = () => setModal(!modal);
  const toggleModalAceite = () => setModalAceite(!modalAceite);
  const toggleModalErro = () => setModalErro(!modalErro);

  const getData = async (page = 1) => {
    try {
      showLoader();
      const query = getQuery(filtrosRef.current.getData());
      const { data } = await api.get(`/operacoes?page=${page}&${query}`);
      setResult({
        pages: Number(data.pages),
        count: Number(data.total),
        items: data.operacoes,
      });
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  };

  async function filtrarConsulta(inputValue, tipo) {
    const { data } = await api.get(`/pessoas?nome=${inputValue}&tipo=${tipo}`);

    return data?.map((m) => ({
      documento: m?.Usuario?.documento,
      value: m?.id,
      label: m?.nome,
    }));
  }

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  const optionsConsulta = async (inputValue, tipo) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current[tipo]);

      selectOptions.current[tipo] = setTimeout(() => {
        resolve(filtrarConsulta(inputValue, tipo));
      }, 500);
    });

  async function handleSubmit(e) {
    const { errors, hasError } = validator(e);

    formRef.current.setErrors(errors);

    if (hasError) {
      return toast();
    }

    try {
      showLoader();
      await api.post('/operacoes/aprovar', {
        ...e,
        operacao_id: modal?.id,
      });
      getData(1);
      toggleModalAceite();
      toggleModal();
      toast('Operação aprovada com sucesso!', { type: 'success' });
    } catch (err) {
      closeLoader();
      toast(err.mensagem);
    }
  }

  const handleRejeitar = (id) => {
    swalWithBootstrapButtons
      .fire({
        title: 'Rejeitar operação?',
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
        showLoaderOnConfirm: true,
      })
      .then(async (res) => {
        if (res.value) {
          showLoader();
          api
            .delete(`/operacoes/rejeitar/${id}`)
            .then(() => {
              toast('Operação rejeitada com sucesso!', { type: 'success' });
              closeLoader();
              getData(1);
              toggleModal();
            })
            .catch((error) => {
              const msg = error?.response?.data?.error;
              toast(msg, { type: 'error' });
              closeLoader();
            });
        }
      });
  };

  const handleDesSugerir = ({ id }) => {
    swalWithBootstrapButtons
      .fire({
        title: 'Des-sugerir operação?',
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
        showLoaderOnConfirm: true,
      })
      .then(async (res) => {
        if (res.value) {
          showLoader();
          api
            .delete(`/operacoes/des-sugerir/${id}`)
            .then(() => {
              toast('Operação des-sugerida com sucesso!', { type: 'success' });
              closeLoader();
              getData(1);
              toggleModal();
            })
            .catch((error) => {
              const msg = error?.response?.data?.error;
              toast(msg, { type: 'error' });
              closeLoader();
            });
        }
      });
  };

  async function reenviarCerc() {
    showLoader();
    try {
      await api.post(`/integracao/reenviar/${erro?.id}`);
      getData(1);
      toggleModalErro();
      toast('Operação reenviada com sucesso!', { type: 'success' });
    } catch (err) {
      toast('Erro ao reenviar para CERC!');
    }
    closeLoader();
  }

  return (
    <>
      <Card>
        <CardHeader className="bg--dark-blue p-3 mb-3 d-flex justify-content-between">
          <h4 className="color-white mb-0">Operações</h4>
        </CardHeader>
        <CardBody>
          <Form
            onSubmit={() => getData()}
            ref={filtrosRef}
            initialData={queryParams}
          >
            <Row>
              {user?.perfil !== 'F' && (
                <Col>
                  <ReactSelectAsync
                    isClearable
                    name="pessoa_id_fomento"
                    label="Financiador"
                    placeholder="Selecione"
                    loadOptions={(txt) => optionsConsulta(txt, 'F')}
                    onChange={filtrarPesquisa}
                    defaultOptions
                  />
                </Col>
              )}
              {user?.perfil !== 'E' && (
                <Col>
                  <ReactSelectAsync
                    isClearable
                    name="pessoa_id_ec"
                    label="Estabelecimento"
                    placeholder="Selecione"
                    loadOptions={(txt) => optionsConsulta(txt, 'E')}
                    onChange={filtrarPesquisa}
                    defaultOptions
                  />
                </Col>
              )}
              <Col>
                <Select
                  name="status"
                  className="form-control"
                  label="Status"
                  onChange={filtrarPesquisa}
                  data={[
                    {
                      value: '',
                      label: 'Status',
                    },
                    {
                      value: 'Sugerido',
                      label: 'Sugerido',
                    },
                    {
                      value: 'Enviado',
                      label: 'Enviado',
                    },
                    {
                      value: 'Finalizado',
                      label: 'Finalizado',
                    },
                    {
                      value: 'Pendente',
                      label: 'Pendente',
                    },
                    {
                      value: 'Cancelado',
                      label: 'Cancelado',
                    },
                    {
                      value: 'Vencido',
                      label: 'Vencido',
                    },
                    {
                      value: 'Erro',
                      label: 'Erro',
                    },
                  ]}
                />
              </Col>
              <Col lg={2}>
                <Input
                  className="form-control"
                  name="sugestao_inicio"
                  label="Data sugestão início"
                  onChange={filtrarPesquisa}
                  type="date"
                  isClearable
                />
              </Col>
              <Col lg={2}>
                <Input
                  className="form-control"
                  name="sugestao_fim"
                  label="Data sugestão fim"
                  onChange={filtrarPesquisa}
                  type="date"
                />
              </Col>
            </Row>
          </Form>
          <Table className="rwd-table min-height" responsive>
            <thead>
              <tr>
                <th
                  style={{
                    minWidth: 145,
                  }}
                >
                  Data de sugestão
                </th>
                <th>Valor total das URs</th>
                <th>Valor livre total</th>
                <th>Total livre Simulado</th>
                <th>Total livre Contratado</th>
                {user?.perfil !== 'F' && <th>Financiador</th>}
                {user?.perfil !== 'E' && <th>Credenciador</th>}
                <th>Status</th>
                <th className="text-end">Ações</th>
              </tr>
            </thead>
            <tbody>
              {result.items.map((m) => (
                <tr key={m?.Usuario?.documento}>
                  <td
                    data-label="Data de sugestão"
                    style={{
                      minWidth: 145,
                    }}
                  >
                    {formatarData(m?.createdAt)}
                  </td>
                  <td data-label="Valor total das URs">
                    {Number(m?.valor_total_ur).toLocaleString(
                      ...currencyConfig
                    )}
                  </td>
                  <td data-label="Valor livre total">
                    {Number(m?.valor_total).toLocaleString(...currencyConfig)}
                  </td>
                  <td data-label="Total livre Simulado">
                    {Number(m?.valor_simulado_total).toLocaleString(
                      ...currencyConfig
                    )}
                  </td>
                  <td data-label="Total livre Contratado">
                    {Number(
                      m?.OperacaoDetalhes?.filter(
                        (x) => x?.status === 'Finalizado'
                      ).reduce(
                        (accumulator, object) =>
                          accumulator + object?.valor_contratado,
                        0
                      )
                    ).toLocaleString(...currencyConfig)}
                  </td>
                  {user?.perfil !== 'F' && (
                    <td data-label="Financiador">
                      <div>{m?.Fomento?.nome}</div>
                    </td>
                  )}

                  {user?.perfil !== 'E' && (
                    <td data-label="Credenciador">
                      <div>{m?.Estabelecimento?.nome}</div>
                    </td>
                  )}
                  <td data-label="Status">{m?.status}</td>
                  <td data-label="Ações">
                    <div className="d-flex justify-content-end">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="light-blue"
                          className="text-decoration-none padding-0-5"
                        >
                          <FiMoreVertical />
                        </DropdownToggle>
                        <DropdownMenu end container="body">
                          {user?.perfil === 'F' && m?.status === 'Sugerido' && (
                            <DropdownItem onClick={() => handleDesSugerir(m)}>
                              <IoTrashOutline className="mr-1" />
                              Excluir sugestão
                            </DropdownItem>
                          )}
                          <DropdownItem onClick={() => setModal(m)}>
                            <IoFolderOutline className="mr-1" />
                            Detalhes operação
                          </DropdownItem>
                          {m?.status === 'Erro' && (
                            <DropdownItem
                              onClick={() => {
                                toggleModalErro();
                                setErro({ id: m?.id, obj: m?.response });
                              }}
                            >
                              <FiEye className="mr-1" />
                              Ver log de erro
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
              ))}
              {result.items?.length === 0 && (
                <tr>
                  <td colSpan={5} className="text-center">
                    Sem dados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
        <div className="d-flex-wrap justify-content-end">
          <p className="mt-1 pr-2">
            {result.items.length}
            {' de '}
            {result.count}
          </p>
          <Pagination
            nextLabel={<FaChevronRight />}
            previousLabel={<FaChevronLeft />}
            pageCount={result.pages}
            onPageChange={({ selected }) => getData(selected + 1)}
            initialPage={0}
            containerClassName="pagination justify-end mr-1"
            activeClassName="active"
          />
        </div>

        <Modal isOpen={modal} toggle={toggleModal} size="xl">
          <ModalHeader toggle={toggleModal}>Detalhes operação</ModalHeader>
          <ModalBody>
            <Table className="rwd-table min-height" responsive>
              <thead>
                <tr>
                  <th>Valor UR</th>
                  <th>Valor a onerar</th>
                  <th>Valor simulado</th>
                  <th>Valor contratado</th>
                  <th>Liquidação</th>
                  <th>Arranjo</th>
                  <th>Credenciadora</th>
                  <th>Detentor</th>
                  <th>Participante</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {modal?.OperacaoDetalhes?.map((m) => (
                  <tr key={m?.id}>
                    <td data-label="Valor UR">
                      {Number(m?.valor_total_ur).toLocaleString(
                        ...currencyConfig
                      )}
                    </td>
                    <td data-label="Valor a onerar">
                      {Number(m?.valor_a_onerar).toLocaleString(
                        ...currencyConfig
                      )}
                    </td>
                    <td data-label="Valor simulado">
                      {Number(m?.valor_simulado).toLocaleString(
                        ...currencyConfig
                      )}
                    </td>
                    <td data-label="Valor contratado">
                      {m?.valor_contratado
                        ? Number(m?.valor_contratado).toLocaleString(
                            ...currencyConfig
                          )
                        : 'N/A'}
                    </td>
                    <td data-label="Liquidação">
                      {new Date(m?.data_inicio).toLocaleDateString('pt-BR', {
                        month: '2-digit',
                        year: '2-digit',
                        day: '2-digit',
                      })}
                    </td>
                    <td data-label="Arranjo">
                      {imgs[m?.codigo_arranjo_pagamento] ? (
                        <ToolTipitem text={nomes[m?.codigo_arranjo_pagamento]}>
                          <img
                            width={60}
                            height={25}
                            alt={m?.bandeira}
                            src={require(`../../assets/svg/bandeiras/${
                              imgs[m?.codigo_arranjo_pagamento]
                            }`)}
                            className="mr-3 object-fit"
                          />
                        </ToolTipitem>
                      ) : (
                        <ToolTipitem text={nomes[m?.codigo_arranjo_pagamento]}>
                          <span className="badge badge-primary color-primary-dark">
                            {m?.codigo_arranjo_pagamento}
                          </span>
                        </ToolTipitem>
                      )}
                    </td>
                    <td data-label="Credenciadora">
                      {maskDocumento(m?.cnpj_credenciadora)}
                    </td>
                    <td data-label="Detentor">
                      {maskDocumento(m?.cnpj_detentor)}
                    </td>
                    <td data-label="Participante">
                      {maskDocumento(m?.cnpj_participante)}
                    </td>
                    <td data-label="Status">{m?.status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalBody>
          {['Sugerido', 'Sugestão'].includes(modal?.status || '') && (
            <>
              {(user?.perfil === 'E' || user?.perfil === 'F') && (
                <>
                  {modal?.sugerido_por !== user.perfil && (
                    <ModalFooter>
                      <Button
                        color="danger"
                        onClick={() => handleRejeitar(modal?.id)}
                        className="mr-1"
                      >
                        Rejeitar operação
                      </Button>
                      <Button color="primary" onClick={toggleModalAceite}>
                        Aceitar operação
                      </Button>
                    </ModalFooter>
                  )}
                </>
              )}
            </>
          )}
        </Modal>

        <Modal isOpen={modalAceite} toggle={toggleModalAceite}>
          <ModalHeader toggle={toggleModalAceite}>Aceitar operação</ModalHeader>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <ModalBody>
              <Row>
                <Col lg={6}>
                  <Input
                    label="Data vencimento"
                    className="form-control"
                    name="data_vencimento"
                    type="date"
                  />
                </Col>
                <Col lg={6}>
                  <Input
                    label="Data assinatura"
                    className="form-control"
                    name="data_assinatura"
                    type="date"
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="primary">
                Aceitar operação
              </Button>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={modalErro} toggle={toggleModalErro} size="lg">
          <ModalHeader toggle={toggleModalErro}>Log de erro CERC</ModalHeader>

          <ModalBody>
            <Row>
              <Col lg={12}>
                <JsonView
                  data={erro?.obj}
                  shouldExpandNode={allExpanded}
                  style={defaultStyles}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <span style={{ color: 'red' }}>
                  Atenção! Ao ser reenviado para CERC, todas as URS vinculadas
                  ao contrato serão reenviadas, é recomendado criar uma nova
                  operação com a(s) UR(s) que retornou erro, caso não seja o
                  total de URs da operação.
                </span>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type="button"
              onClick={() => reenviarCerc()}
            >
              Reenviar para CERC
            </Button>
          </ModalFooter>
        </Modal>
      </Card>
    </>
  );
}

export default ListarOperacoes;
