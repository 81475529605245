import React, { useRef, useMemo, useEffect } from 'react';
import { Form } from '@unform/web';
import {
  Button,
  Card,
  Col,
  Row,
  CardBody,
  CardHeader,
  CardFooter,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Input, MaskInput, CheckBox } from '../../components/unform';
import useValidator from '../../hooks/useValidator';
import api from '../../services/api';

function CriarAcordo() {
  const { showLoader, closeLoader, toast } = useValidator();
  const history = useHistory();
  const formRef = useRef();
  const query = useMemo(
    () =>
      String(history?.location?.search || '')
        .replace('?', '')
        .split('&')
        .reduce((a, b) => {
          const [key, value] = b.split('=');
          a[key] = value;
          return a;
        }, {}),
    [history?.location?.search]
  );

  async function handleSubmit(dados) {
    const errors = {};
    const fields = ['nome', 'email', 'documento'];

    fields.forEach((f) => {
      if (!dados[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      return toast();
    }

    showLoader();
    try {
      const arrayPermissoes = [];
      Object.keys(dados).forEach((key) => {
        if (typeof dados[key] !== 'string') {
          if (Boolean(dados[key]) === true) {
            arrayPermissoes.push(Number(key));
          }
        }
      });

      const body = {
        email: dados.email,
        nome: dados.nome,
        documento: dados.documento,
        acessos: arrayPermissoes,
      };

      if (query?.id) {
        await api.put(`/usuarios/internos/${query?.id}`, body);
      } else {
        await api.post('/usuarios/internos', body);
      }

      toast('Usuário criado com sucesso!', { type: 'success' });
      history.push('/usuarios');
    } catch (err) {
      toast('Erro ao criar usuário interno!', { type: 'error' });
    }
    closeLoader();
  }

  useEffect(() => {
    const getEditData = async (id) => {
      try {
        showLoader();
        const { data } = await api.get(`/usuario/interno/${id}`);
        const { acessos, usuario } = data;
        const body = {
          ...(usuario || {}),
        };
        if (acessos?.length) {
          acessos?.forEach((f) => {
            body[f.acessos] = f.acessos;
          });
        }
        formRef.current.setData(body);
        closeLoader();
      } catch (err) {
        toast(err.mensagem);
        closeLoader();
      }
    };

    if (query.id) {
      getEditData(query.id);
    }
  }, [query]);

  return (
    <>
      <Card>
        <CardHeader className="bg--dark-blue p-3 mb-3">
          <h4 className="color-white mb-0">Novo usuário interno</h4>
        </CardHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <CardBody>
            <Row>
              <Col lg={4} md={6} sm={12}>
                <Input name="nome" label="Nome *" className="form-control" />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <Input name="email" label="E-mail *" className="form-control" />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <MaskInput
                  name="documento"
                  label="CPF *"
                  className="form-control"
                  mask={[
                    /[0-9]/,
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <label className="label-form">Permissões</label>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="1" label="Dashboard" value="1" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="2" label="Cadastros" value="2" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="3" label="Acordos" value="3" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="4" label="Agenda" value="4" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="5" label="Operações" value="5" />
              </Col>
              {/* <Col lg={4} md={4} sm={6}>
                <CheckBox name="6" label="Usuários Internos" value="6" />
              </Col> */}
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="7" label="Logs" value="7" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="8" label="Webhooks" value="8" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="9" label="Conciliação" value="9" />
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="justify-content-between d-flex">
            <p className="mb-0 text-muted"> * (Obrigatório)</p>
            <Button type="submit" color="primary">
              {query?.id ? 'Alterar' : 'Cadastrar'}
            </Button>
          </CardFooter>
        </Form>
      </Card>
    </>
  );
}

export default CriarAcordo;
