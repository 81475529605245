/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useRef, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Form } from '@unform/web';
import Pagination from 'react-paginate';
import {
  Card,
  CardBody,
  CardHeader,
  Table,
  Row,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledCollapse,
  Button,
} from 'reactstrap';
import { FiMoreVertical } from 'react-icons/fi';
import { IoChevronDown, IoFolderOutline } from 'react-icons/io5';
import JsonView from '@uiw/react-json-view';
import api from '../../services/api';
import useValidator from '../../hooks/useValidator';
import { maskDocumento } from '../../utils';
import { currencyConfig } from '../../utils/currency';
import { Input, ReactSelectAsync, Select } from '../../components/unform';
import getQuery from '../../utils/getQuery';
import { useAuth } from '../../contexts/auth';

function formatarData(dataString) {
  if (dataString) {
    return new Date(dataString)
      .toLocaleDateString('pt-BR', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
      .replace(',', '');
  }

  return '';
}

function ListIntegracoes() {
  const { showLoader, closeLoader, toast } = useValidator();
  const { user } = useAuth();
  const [modal, setModal] = useState(false);
  const [result, setResult] = useState({
    items: [],
    pages: 0,
    count: 0,
    itemsPorPagina: 0,
  });
  const filtrosRef = useRef();
  const debounce = useRef();
  const selectOptions = useRef({});

  const toggleModal = () => {
    setModal(!modal);
  };

  const getData = async (page = 1) => {
    try {
      showLoader();
      const query = getQuery(filtrosRef.current.getData());
      const { data } = await api.get(`/listarinegracoes?page=${page}&${query}`);
      setResult({
        pages: Number(data.pages),
        count: Number(data.total),
        items: data.integracoesEnvio.map((m) => ({
          ...m,
          retorno: data?.integracoesRetorno?.find(
            (f) => f.integracao_id === m.id
          ),
        })),
      });
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  };

  async function filtrarConsulta(inputValue, tipo) {
    const { data } = await api.get(`/pessoas?nome=${inputValue}&tipo=${tipo}`);

    return data?.map((m) => ({
      documento: m?.Usuario?.documento,
      value: m?.Usuario?.documento,
      label: m?.nome,
    }));
  }

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  const optionsConsulta = async (inputValue, tipo) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current[tipo]);

      selectOptions.current[tipo] = setTimeout(() => {
        resolve(filtrarConsulta(inputValue, tipo));
      }, 500);
    });

  return (
    <>
      <Card>
        <CardHeader className="bg--dark-blue p-3 mb-3 d-flex justify-content-between">
          <h4 className="color-white mb-0">Integrador</h4>
        </CardHeader>
        <CardBody>
          <Form onSubmit={() => getData()} ref={filtrosRef}>
            <Row>
              {user?.perfil !== 'F' && (
                <Col>
                  <ReactSelectAsync
                    isClearable
                    name="cnpj_fomento"
                    label="Financiador"
                    placeholder="Selecione"
                    loadOptions={(txt) => optionsConsulta(txt, 'F')}
                    onChange={filtrarPesquisa}
                    defaultOptions
                  />
                </Col>
              )}
              {user?.perfil !== 'E' && (
                <Col>
                  <ReactSelectAsync
                    isClearable
                    name="documento_ec"
                    label="Estabelecimento"
                    placeholder="Selecione"
                    loadOptions={(txt) => optionsConsulta(txt, 'E')}
                    onChange={filtrarPesquisa}
                    defaultOptions
                  />
                </Col>
              )}
              <Col>
                <Input
                  className="form-control"
                  name="codigo_erp"
                  label="Código ERP"
                  onChange={filtrarPesquisa}
                />
              </Col>
              <Col>
                <Select
                  name="status"
                  className="form-control"
                  label="Status"
                  onChange={filtrarPesquisa}
                  data={[
                    {
                      value: '',
                      label: 'Status',
                    },
                    {
                      value: 'Enviado',
                      label: 'Enviado',
                    },
                    {
                      value: 'Pendente',
                      label: 'Pendente',
                    },
                    {
                      value: 'Erro',
                      label: 'Erro',
                    },
                  ]}
                />
              </Col>
              <Col lg={2}>
                <Input
                  className="form-control"
                  name="sugestao_inicio"
                  label="Data sugestão início"
                  onChange={filtrarPesquisa}
                  type="date"
                  isClearable
                />
              </Col>
              <Col lg={2}>
                <Input
                  className="form-control"
                  name="sugestao_fim"
                  label="Data sugestão fim"
                  onChange={filtrarPesquisa}
                  type="date"
                />
              </Col>
            </Row>
          </Form>
          <Table className="rwd-table min-height" responsive>
            <thead>
              <tr>
                <th>Financiador</th>
                <th>Doc. EC</th>
                <th>Código ERP</th>
                <th>Status</th>
                <th className="text-end">Ações</th>
              </tr>
            </thead>
            <tbody>
              {result.items.map((m) => (
                <tr key={m?.id}>
                  <td data-label="Financiador">
                    {maskDocumento(m?.cnpj_fomento)}
                  </td>
                  <td data-label="Doc. EC">{maskDocumento(m?.documento_ec)}</td>
                  <td data-label="Código ERP">{m?.codigo_erp}</td>
                  <td data-label="Status">{m?.status}</td>
                  <td data-label="Ações">
                    <div className="d-flex justify-content-end">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="light-blue"
                          className="text-decoration-none padding-0-5"
                        >
                          <FiMoreVertical />
                        </DropdownToggle>
                        <DropdownMenu end container="body">
                          <DropdownItem onClick={() => setModal(m)}>
                            <IoFolderOutline className="mr-1" />
                            Ver todos os dados
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
              ))}
              {result.items?.length === 0 && (
                <tr>
                  <td colSpan={5} className="text-center">
                    Sem dados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
        <div className="d-flex-wrap justify-content-end">
          <p className="mt-1 pr-2">
            {result.items.length}
            {' de '}
            {result.count}
          </p>
          <Pagination
            nextLabel={<FaChevronRight />}
            previousLabel={<FaChevronLeft />}
            pageCount={result.pages}
            onPageChange={({ selected }) => getData(selected + 1)}
            initialPage={0}
            containerClassName="pagination justify-end mr-1"
            activeClassName="active"
          />
        </div>
      </Card>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Dados</ModalHeader>

        <ModalBody>
          <Table>
            <tbody>
              <tr>
                <th>ID</th>
                <td>{modal?.id}</td>
              </tr>
              <tr>
                <th>CNPJ Financiador</th>
                <td>{maskDocumento(modal?.cnpj_fomento)}</td>
              </tr>
              <tr>
                <th>Documento EC</th>
                <td>{maskDocumento(modal?.documento_ec)}</td>
              </tr>
              <tr>
                <th>Fator Compra</th>
                <td>{Number(modal?.fator_compra).toLocaleString('pt-BR')}</td>
              </tr>
              <tr>
                <th>Outras Despesas</th>
                <td>
                  {Number(modal?.outras_despesas).toLocaleString(
                    ...currencyConfig
                  )}
                </td>
              </tr>
              <tr>
                <th>Valor Simulado</th>
                <td>
                  {Number(modal?.valor_simulado).toLocaleString(
                    ...currencyConfig
                  )}
                </td>
              </tr>
              <tr>
                <th>Código ERP</th>
                <td>{modal?.codigo_erp}</td>
              </tr>
              <tr>
                <th>Valor UR</th>
                <td>
                  {Number(modal?.valor_ur).toLocaleString(...currencyConfig)}
                </td>
              </tr>
              <tr>
                <th>Deságio</th>
                <td>
                  {Number(modal?.desagio).toLocaleString(...currencyConfig)}
                </td>
              </tr>
              <tr>
                <th>Operação ID</th>
                <td>{modal?.operacao_id}</td>
              </tr>
              <tr>
                <th>Operação Detalhe ID</th>
                <td>{modal?.operacao_detalhe_id}</td>
              </tr>
              <tr>
                <th>Data Liquidação</th>
                <td>{formatarData(modal?.data_liquidacao)}</td>
              </tr>
              <tr>
                <th>Objeto</th>
                <td>
                  <JsonView
                    collapsed
                    displayDataTypes={false}
                    displayObjectSize={false}
                    value={JSON.parse(modal?.objeto || '{}')}
                  />
                </td>
              </tr>

              <tr>
                <th>Objeto Retorno 1</th>
                <td>
                  <JsonView
                    collapsed
                    displayDataTypes={false}
                    displayObjectSize={false}
                    value={JSON.parse(modal?.objeto_retorno || '{}')}
                  />
                </td>
              </tr>
              <tr>
                <th>Objeto Retorno 2</th>
                <td>
                  <JsonView
                    collapsed
                    displayDataTypes={false}
                    displayObjectSize={false}
                    value={JSON.parse(modal?.retorno?.objeto || '{}')}
                  />
                </td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{modal?.status}</td>
              </tr>
              <tr>
                <th>Retorno ID</th>
                <td>{modal?.retorno?.id}</td>
              </tr>
              <tr>
                <th>Saldo Devedor</th>
                <td>
                  {Number(modal?.retorno?.saldo_devedor).toLocaleString(
                    ...currencyConfig
                  )}
                </td>
              </tr>
              <tr>
                <th>Limite Operação Garantida</th>
                <td>
                  {Number(
                    modal?.retorno?.limite_operacao_garantida
                  ).toLocaleString(...currencyConfig)}
                </td>
              </tr>
              <tr>
                <th>Valor Mantido</th>
                <td>
                  {Number(modal?.retorno?.valor_mantido).toLocaleString(
                    ...currencyConfig
                  )}
                </td>
              </tr>
              <tr>
                <th>Status Retorno</th>
                <td>{modal?.retorno?.status}</td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ListIntegracoes;
